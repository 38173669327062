h1 {
  text-align: center;
}

h2 {
  margin-top: 40px;
  margin-bottom: 0;
}

.input-label, .input-adorment {
  
}

.result-number {
  font-size: 60px;
  color: #168118;
}

.center-text {
  text-align: center;
}